
import { defineComponent, ref } from "vue";
import MenuTab from "@/components/common/MenuTab.vue";
import axios from "@/api/axios";
import list from "@/components/common/list.vue";
import Toast from "@/components/UI/Toast";
import { calendarFormat } from "@/utils/dateFormat";
import BalanceItem from "@/components/common/BalanceItem.vue";
import AccountItem from "@/components/common/AccountItem.vue";
export default defineComponent({
  name: "Bill",
  components: {
    MenuTab,
    list,
    BalanceItem,
    AccountItem
  },

  setup() {
    type Menu = "我的货款" | "我的余额";
    const menu: Menu[] = ["我的货款", "我的余额"];
    const currentMenuItem = ref(menu[0]);
    function selectMenu(item: Menu) {
      currentMenuItem.value = item;
    }
    return {
      menu,
      currentMenuItem,
      selectMenu,
      calendarFormat,
    };
  },
});
