<template>
  <transition-group tag="ul" name="list" class="order-ul">
    <li v-for="item in myBalance.data" :key="item.orderNum">
      <div class="order-date">流水编号：{{ item.orderNum }}</div>
      <div class="spend-date">
        <span>{{ calendarFormat(item.addTime)[2] }}</span>
        <p>
          {{ calendarFormat(item.addTime)[1] }}
          {{ calendarFormat(item.addTime)[0] }}
        </p>
      </div>
      <div class="order-date" v-if="item.payType == 0">
        支付方式： 余额支付
      </div>
      <div class="order-date" v-if="item.payType == 1">
        支付方式： 微信支付
      </div>
      <div class="order-date" v-if="item.payType == 2">
        支付方式： 支付宝支付
      </div>
      <div class="order-date" v-if="item.payType == 3">
        支付方式： 月结付款
      </div>
      <div class="order-date" v-if="item.payType == 4">
        支付方式： 网银转账
      </div>
      <div class="order-date" v-if="!item.payType">
        支付方式： 暂无
      </div>
      <div class="order-date">
        充值金额：￥{{ Number(item.totalPrice).toFixed(2) }}
      </div>
      <div class="spend-date" style="top: 28px;">
        <span>{{ calendarFormat(item.createTime)[2] }}</span>
        <p>
          {{ calendarFormat(item.createTime)[0] }}/{{ calendarFormat(item.createTime)[1] }}
        </p>
      </div>
    </li>
  </transition-group>
  <div class="balance" v-if="balance && myBalance && myBalance.data.length">
    余额：<span style="color: red"> ￥{{ Number(balance).toFixed(2) }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, inject, watch, ref, reactive } from "vue";
import { calendarFormat} from "@/utils/dateFormat";


export default defineComponent({
  name: "InvoiceItem",
  props: {
    myBalance: Object,
  },
  
  setup(props) {
    const balance = ref()
    watch(
        () => {
          return props.myBalance
        },
        (NewsVue) => {
          balance.value = (NewsVue as any).totalResult && (NewsVue as any).totalResult[0] && (NewsVue as any).totalResult[0].str
        }
    )
    return {
      calendarFormat,
      balance
    };
  }
});
</script>


<style lang="scss" scoped>
main {
  padding-bottom: 110px;
}
.order-ul li {
  box-sizing: border-box;
  padding: 15px;
  padding-bottom: 15px;
  overflow: hidden;
  border-bottom: 5.6px solid #f4f4f4;
  position: relative;
  padding-left: 70px;
}

.spend-date {
  position: absolute;
  left: 15px;
  // top: 20px;
  top: 38px;
  color: #666;
}
.spend-date span {
  font-size: 22px;
  text-align: center;
  display: block;
}
.spend-date p {
  font-size: 14px;
  border-top: 1px solid #e5e5e5;
  padding-top: 5px;
}

.order-ul .title {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #eee;
  font-size: 15px;
  color: #444;
  position: relative;
}
.price {
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  line-height: 40px;
  color: rgb(102, 102, 102);
}
.order-date {
  margin: 0 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px 0 5px 0;
  padding-left: 10px;
  font-size: 14px;
  box-sizing: border-box;
  color: #666;
  position: relative;
}

.order-btn {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -11.25px;
  text-align: center;
  width: 80px;
  height: 22.5px;
  border-radius: 15px;

  line-height: 22.5px;

  font-size: 14px;
  color: #df0034;
  border: solid 1px #b9135B;
}

.balance {
  position: fixed;
  color: #666;
  bottom: 50px;
  left: 0;
  right: 0;
  height: 50px;
  line-height: 50px;
  padding-left: 25px;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
  font-size: 14px;
  font-weight: bold;
  background-color: #fff;
  z-index: 9;
}
</style>